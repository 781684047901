<template>
  <div>
<div class=" text-right">
  <!-- <locale-a /> -->
</div>
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <!-- Login v1 -->
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <img src="../assets/images/logo/logo.png" alt="logo" width="75" />
            <img
              src="../assets/images/logo/logop2.png"
              class="mt-2 ml-1"
              alt="logo"
              height="35"
            />
          </b-link>
  
          <b-card-title class="mb-1"
            > {{ $t('Wellcome') }} 👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Observation')}}
          </b-card-text>
  
          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label-for="email" :label="$t('Email')">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    name="login-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                    autofocus
                  />
                </validation-provider>
              </b-form-group>
  
              <!-- password -->
              <b-form-group label-for="password" :label="$t('Password')">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="login-password"
                      placeholder="Password"
                    />
  
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
  
              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="remember-me"
                >
                  {{ $t('Remember Me') }}
                </b-form-checkbox>
              </b-form-group>
  
              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                block
                :disabled="invalid"
                @click="login"
              >
                {{ $t('login') }}
              </b-button>
              <b-form-group class="mt-1" >
                    <b-link   @click="forgetPassword()">
                      <small>{{ $t('Forget Password')}}</small>
                    </b-link>
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-card>
        <!-- /Login v1 -->
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import localeA from "@core/layouts/components/app-navbar/components/LocaleA"

export default {
  components: {
    localeA,
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: "",
      password: "",
      status: "",
      // validation rules
      required,
      email,
      passwordFieldType: "password",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    login() {
      const self = this;
      let api = this.$store.state.api + "login";

      axios
        .post(api, {
          email: self.email,
          password: self.password,
        })
        .then((response) => {
          self.$store.commit("auth", response.data.access_token);
          self.$router.push({ path: "/" });
          // this.$router.push('/').then(() => {
          //     this.clearCache();
          //     window.location.reload(true); // Recarrega a página
          //   });
                      
        })
        .catch((error) => {
          this.$error(error);
        });
      },
      clearCache() {
      console.log('Limpando cache...');
      // Limpa localStorage
      localStorage.clear();

      // Limpa sessionStorage
      sessionStorage.clear();

      // Opcionalmente, você pode limpar cookies (se aplicável)
      document.cookie.split(';').forEach((cookie) => {
        const [name] = cookie.split('=');
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
      });
    },
      forgetPassword() {
        this.$router.push("/auth-forgot-password");
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
